import { useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  Icon,
  List,
  ListItem,
  TextLink,
  Paragraph,
} from 'darrius';

import { type IServicesCategories } from '../../../locales/@interfaces/home/servicesCategories';

import styles from './ServicesCategories.module.scss';

export interface IServicesCategoriesProps {
  data: IServicesCategories;
  floating?: boolean;
  floatingStart?: number;
  className?: string;
}

const ServicesCategories = ({
  data,
  floating = true,
  floatingStart = 0,
  className = '',
}: IServicesCategoriesProps) => {
  const { title, items, seeMore, seeLess } = data;

  const scrollHandler = useCallback(() => {
    const servicesCategoriesElement = document.querySelector(
      '.services-categories'
    );

    if (servicesCategoriesElement && window.scrollY > floatingStart) {
      servicesCategoriesElement.classList.add(
        `${styles['services-categories--fixed']}`
      );
    } else {
      servicesCategoriesElement.classList.remove(
        `${styles['services-categories--fixed']}`
      );
    }
  }, [floatingStart]);

  useEffect(() => {
    if (floating) {
      window.addEventListener('scroll', scrollHandler);

      return () => {
        window.removeEventListener('scroll', scrollHandler);
      };
    }
  }, [floating, scrollHandler]);

  return (
    <Card
      className={`services-categories ${styles['services-categories']} ${className}`}
    >
      <CardHeader>
        <Row className="center-sm middle-sm">
          <h2 className={`${styles.title} title`}>{title}</h2>
        </Row>
      </CardHeader>

      <input
        type="checkbox"
        id="show-more"
        className={`${styles['show-more']} show-more`}
        hidden
        aria-hidden
      />

      <Row
        className={`${styles.categories} categories center-sm between-md space-between`}
      >
        {items.map((category, i) => (
          <Col
            key={category.text}
            className={`col-sm-4 col-md-1 ${i > 5 && styles['hide-sm']}`}
          >
            <Row className="center-sm">
              <a
                href={category.slug}
                className={`${styles.category} category`}
                onClick={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: 'select_item',
                    category: category.text,
                  })
                }}
              >
                <Icon src={category.img} alt={category.text} size={48} />

                <p className={`${styles.category__text} category__text`}>
                  {category.text}
                </p>
              </a>

              {category.subcategories && (
                <div className={`${styles.subcategories} subcategories`} hidden>
                  <Row className="start-sm">
                    {category.subcategories.map((subcategory) => (
                      <Col
                        className={`col-sm-${subcategory.cols}`}
                        key={subcategory.title}
                      >
                        <Paragraph title={subcategory.title} size="4" />

                        <List
                          className={
                            subcategory.twoColumns ? styles.twocolumns : ''
                          }
                        >
                          {subcategory.items.map((item) => (
                            <ListItem
                              className={`${styles['service-link']} service-link`}
                              key={item.text}
                            >
                              <TextLink
                                className={`${styles['text-link__gray']} text-link__gray`}
                                text={item.text}
                                url={item.url}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Col>
                    ))}
                  </Row>
                </div>
              )}
            </Row>
          </Col>
        ))}
      </Row>

      {items.length > 3 && (
        <label
          htmlFor="show-more"
          className={`${styles.button} button clear hide-md`}
          aria-hidden="true"
        >
          <span className={`${styles.more} more`}>{seeMore}</span>

          <span className={`${styles.less} less`} hidden>
            {seeLess}
          </span>
        </label>
      )}
    </Card>
  );
};

export default ServicesCategories;
