/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Header as DarriusHeader } from 'darrius';

import { useClientUser } from '../../../hooks/useClientUser';

import { type IHeaderContent } from '../../../locales/@interfaces/shared/headerContent';
import { type IUserCliDataResponse } from '../../../modules/user';

interface IHeaderDefault {
  header?: IHeaderContent;
  onlyLogo?: boolean;
}

function Default({ header, onlyLogo = false }: IHeaderDefault) {
  return (
    <DarriusHeader.Root
      onlyLogo={onlyLogo}
      logoLink={process.env.NEXT_PUBLIC_HOST}
    >
      <DarriusHeader.NavMenu>
        {header && header.signup && <DarriusHeader.Link {...header.signup} />}
        {header && header.about && <DarriusHeader.Link {...header.about} />}
        {header && header.security && (
          <DarriusHeader.Link {...header.security} />
        )}
      </DarriusHeader.NavMenu>
    </DarriusHeader.Root>
  );
}

interface IHeaderAuth {
  userData: IUserCliDataResponse;
  header?: IHeaderContent;
  options?: {
    hideMessages?: boolean;
    hideOrders?: boolean;
  };
}

function Auth({
  header,
  userData,
  options = {
    hideMessages: false,
    hideOrders: false,
  },
}: IHeaderAuth) {
  const { handleUserSignOut, isLoadingUser } = useClientUser({
    shouldFetchUserData: false,
  });

  return (
    <DarriusHeader.Root logoLink={process.env.NEXT_PUBLIC_HOST}>
      <DarriusHeader.NavMenu
        notificationsTotalCount={userData.notificationCount}
      >
        {header && (
          <>
            {header.signup && <DarriusHeader.Link {...header.signup} />}
            {header.about && <DarriusHeader.Link {...header.about} />}
            {header && header.security && (
              <DarriusHeader.Link {...header.security} />
            )}
          </>
        )}

        <DarriusHeader.Auth
          isLogged={userData.isAuthenticated}
          onLogout={handleUserSignOut}
          isLoading={isLoadingUser}
          user={{
            avatar: userData?.avatar,
            name: userData?.name,
            phone: userData?.phone,
          }}
        >
          {!options.hideMessages && (
            <DarriusHeader.AuthItem
              text="Mensagens"
              link="/mensagens"
              notificationCount={userData.notificationCount}
            />
          )}

          {!options.hideOrders && (
            <DarriusHeader.AuthItem text="Meus Pedidos" link="/meus-pedidos" />
          )}
        </DarriusHeader.Auth>
      </DarriusHeader.NavMenu>
    </DarriusHeader.Root>
  );
}

export { Default, Auth };
